import styled from '@emotion/styled';

const Rows = styled('ul')({
  display: 'inline-block',
  listStyle: 'none',
  margin: '1.5rem 0 0',
  padding: 0,
  verticalAlign: 'middle',
  width: '100%',
  zoom: 1,
});

export default Rows;
